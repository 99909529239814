<template>
  <div id="query_use">
    <div class="log-management">
    <strong style="font-size:13px">登录日志</strong>
    <div class="buttons">
    <el-input
      v-model="state"
      placeholder="请输入需要搜索的姓名"
      @keyup.enter.native="$event.target.blur"
      @blur="querySearchAsync(state)"
      style="width:16vw;"
      clearable
    ><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
    <el-button @click="querySearchAsync(state)" type="primary" size="mini"  style="line-height: 20px;"  icon="el-icon-search">搜索
    </el-button>
    <el-button @click="refresh()" type="primary" size="mini"  style="line-height: 20px;" >重置
    </el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table :data="tableData"  :row-class-name="tableRowClassName"  :row-style="{height: '0.1'}" :cell-style="{padding: '4px -15px'}">
        <el-table-column prop="employee" label="登录人" align="center"></el-table-column>
        <el-table-column prop="loginname" label="登录账号" align="center"></el-table-column>
        <el-table-column prop="unitname" label="单位" align="center"></el-table-column>
        <el-table-column prop="info" label="描述" align="center"></el-table-column>
        <el-table-column prop="logintime" label="登录时间" align="center"></el-table-column>
        <el-table-column label="结果" align="center" >
          <template slot-scope="scope">
                <el-tag type="success" effect="dark" v-if="scope.row.result==1">成功</el-tag>
                <el-tag type="danger" effect="dark" v-if="scope.row.result==0">失败</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
  <div style="text-align: right; padding: 30px">
      <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page.sync="currentpage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalcount">
    </el-pagination>
  </div>
</div>
</template>

<script>
export default {
  name: 'query_unit',
  components: {
  },
  data () {
    return {
      // 默认显示第几页
      currentpage: 1,
      // 总条数
      totalcount: 20,
      // 默认每页显示的条数
      pagesize: 10,
      tableData: [],
      state: '',
      currentPage3: 1,
      dialogFormVisible: false,
      pwVisible: false,
      editForm: {
        name: '',
        loginname: '',
        unitname: '',
        info: '',
        result: ''
      },
      pwtable: {},
      level: '',
      units: []
    }
  },
  created () {
    this.level = localStorage.getItem('role_id')
    this.getdatas(this.pagesize, this.currentpage - 1)
  },
  methods: {
    querySearchAsync (search) {
      console.log(search)
      this.axios.post('/login/logsearch/', {
        employeeid: localStorage.getItem('user_id'),
        unitid: localStorage.getItem('unit_id'),
        roleid: localStorage.getItem('role_id'),
        rolename: localStorage.getItem('role_name'),
        searchname: search
      }).then((res) => {
        this.tableData = res.data.data
        this.totalcount = ''
      }).catch((error) => {
        console.log(error)
      })
    },
    getdatas (n1, n2) {
      this.axios.post('/login/log/', {
        employeeid: localStorage.getItem('user_id'),
        unitid: localStorage.getItem('unit_id'),
        roleid: localStorage.getItem('role_id'),
        rolename: localStorage.getItem('role_name'),
        limit: n1,
        offset: n2
      }).then((res) => {
        this.tableData = res.data.data
        this.totalcount = res.data.numbers[0].numbers
      }).catch((error) => {
        console.log(error)
      })
    },
    showEditDialog (row) {
      this.editForm = row
      this.editDialogVisible = true
    },
    showpwDialog (row) {
      this.editForm = row
      this.pwVisible = true
    },
    addUserget () {
      this.addUserVisible = false
      this.getdatas(this.pagesize, this.currentpage - 1)
    },
    editDialogClosed () {
      this.getdatas(this.pagesize, this.currentpage - 1)
      this.editDialogVisible = false
    },
    addUser (data) {
    },
    refresh () {
      this.state = ''
      this.getdatas(this.pagesize, this.currentpage - 1)
    },

    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
    },
    // 显示第几页
    handleCurrentChange (val) {
      console.log('显示第几页', val, val * 10)
      // 改变第几页
      this.currentpage = val
      this.getdatas(this.pagesize, (val - 1) * 10)
    }
  }
}
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}
.el-divider{
   margin: 1px;
}
.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
}
.log-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.log-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}
</style>
